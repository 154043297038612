// menu------------------------------------------------------
const hamburger = document.querySelector('.hamburger');
const nav = document.querySelector('.navigation');
const dark = document.querySelector('.dark');

const handleClick = () => {
  hamburger.classList.toggle('hamburger--active');
  nav.classList.toggle('navigation--active');
  dark.classList.toggle('dark--active');

}

hamburger.addEventListener('click', handleClick);
dark.addEventListener('click', handleClick);

function placeImage(x)
{
    var div = document.getElementById("div_picture_right");

    div.innerHTML = ""; // clear images

    for (counter=1;counter<=x;counter++) {
        var imagem=document.createElement("img");
        imagem.src="borboleta/Borboleta"+counter+".png";
        div.appendChild(imagem);
    }
}